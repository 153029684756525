import { AuthConfirmation, AuthForm, AuthLayout, useAuthState, useGuardGuestOnly } from "modules/auth";
import Link from "next/link";
import pages from "pages/_router";
import React, { useCallback, useEffect, useState } from "react";
import { getQueryParam } from "shared/lib";

const LoginPage = () => {
  useGuardGuestOnly();

  const [authState, setAuthState] = useAuthState();
  const onReset = useCallback(() => setAuthState(undefined), [setAuthState]);

  const [next, setNext] = useState("");

  useEffect(() => {
    const next = getQueryParam("next");
    if (next) setNext("?next=" + next);
  });

  if (authState) {
    return (
      <AuthLayout>
        <AuthLayout.Main>
          <AuthConfirmation email={authState.email} onReset={onReset} />
        </AuthLayout.Main>
      </AuthLayout>
    );
  }

  return (
    <AuthLayout>
      <AuthLayout.Main>
        <h1>Вход в&nbsp;Control&nbsp;Online</h1>

        <AuthForm onSuccess={setAuthState} />
      </AuthLayout.Main>

      <AuthLayout.Bottom>
        <Link href={pages.signup.href + next}>Еще нет аккаунта? Зарегистрироваться</Link>
      </AuthLayout.Bottom>
    </AuthLayout>
  );
};

export default LoginPage;
