import React, { CSSProperties } from "react";
import cx from "classnames";
import styles from "./auth-confirmation.module.scss";

export interface IAuthConfirmation {
  email: string;
  onReset: () => void;

  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  htmlId?: string;
}

export const AuthConfirmation = (props: IAuthConfirmation) => {
  return (
    <div className={cx(styles.auth_confirmation, props.className)} style={props.style} id={props.htmlId}>
      <h1>Ожидаем подтверждения</h1>
      <strong>Не закрывайте это окно, пока не перейдете по ссылке из письма.</strong>

      <p className={styles.text_wrapper}>
        Мы отправили письмо на <strong>{props.email}</strong> (<a onClick={props.onReset}>отменить</a>)
        <br />
        Перейдите по ссылке из письма, чтобы мы вас узнали.
      </p>
    </div>
  );
};

AuthConfirmation.defaultProps = {};
