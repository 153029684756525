import { useEffect, useState } from "react";
import { api, setApiKey, UserAuthStateDto } from "shared/api";

export const useAuthState = (): ReturnType<typeof useState<UserAuthStateDto>> => {
  const [savedAuthState, setSavedAuthState] = useState<UserAuthStateDto>();

  const { data: authState } = api.userAuth.useUserAuthAuthState(savedAuthState?.key!, { refreshInterval: 1000 });

  useEffect(() => {
    if (authState?.activated) {
      api.userAuth.userAuthApiKey(authState.key).then((it) => setApiKey(it.value));
    }
  }, [authState?.activated, authState?.key]);

  return [authState || savedAuthState, setSavedAuthState];
};
