import React, { CSSProperties } from "react";
import cx from "classnames";
import styles from "./auth-layout-main.module.scss";

export interface IAuthLayoutMain {
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  htmlId?: string;
}

export const AuthLayoutMain = (props: IAuthLayoutMain) => {
  return (
    <div className={cx(styles.auth_layout_main, props.className)} style={props.style} id={props.htmlId}>
      {props.children}
    </div>
  );
};

AuthLayoutMain.defaultProps = {};
