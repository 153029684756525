import React, { useEffect, useState } from "react";
import {
  FieldPath,
  FieldValues,
  RegisterOptions,
  UseControllerProps,
  useForm,
  UseFormProps,
  UseFormRegisterReturn,
  UseFormReturn,
} from "react-hook-form";

export interface IUseAppFormProps<
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object,
  TResponse extends any = void
> extends UseFormProps<TFieldValues, TContext> {
  onSubmit: (value: TFieldValues) => Promise<TResponse> | TResponse;
  onSuccess?: (resp: TResponse, value: TFieldValues) => void;
}

export type UseAppFormRegister<TFieldValues extends FieldValues> = <
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  name: TFieldName,
  options?: RegisterOptions<TFieldValues, TFieldName>
) => UseFormRegisterReturn<TFieldName> & { error?: string };

export interface IUseAppForm<TFieldValues extends FieldValues = FieldValues> extends UseFormReturn<TFieldValues> {
  onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
  globalError?: string;
  setGlobalError: (message?: string) => void;
  register: UseAppFormRegister<TFieldValues>;

  regField: <TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
    name: TFieldName,
    rules?: Omit<RegisterOptions<TFieldValues, TFieldName>, "valueAsNumber" | "valueAsDate" | "setValueAs">
  ) => UseControllerProps<TFieldValues, TFieldName>;
}

export const useAppForm = <
  TFieldValues extends FieldValues = FieldValues,
  TResponse extends any = any,
  TContext extends object = object
>(
  props: IUseAppFormProps<TFieldValues, TContext, TResponse>
): IUseAppForm<TFieldValues> => {
  const form = useForm<TFieldValues, TContext>({ mode: "all", ...props });

  const [globalError, setGlobalError] = useState<string>();
  const cleanGlobalError = () => setGlobalError(undefined);

  useEffect(() => {
    form.trigger();
  }, []);

  const onSubmit = form.handleSubmit(async (values) => {
    try {
      const resp = await props.onSubmit(values as any);
      await props.onSuccess?.(resp, values as any);
      cleanGlobalError();
    } catch (e: any) {
      try {
        const response = await e.json();
        if (response?.message) setGlobalError(response?.message);
        if (response?.fields) {
          Object.entries(response?.fields).forEach(([key, value]) => {
            form.setError(key as FieldPath<TFieldValues>, { message: value + "", type: "manual" });
          });
        }
      } catch (ignore) {
        console.error(e);
      }
    }
  });

  const register = (name: FieldPath<TFieldValues>, options: any) => ({
    ...form.register(name, { required: true, ...options }),
    error: (form.formState.errors as any)?.[name]?.message,
  });

  const reg = <TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
    name: TFieldName,
    rules?: Omit<RegisterOptions<TFieldValues, TFieldName>, "valueAsNumber" | "valueAsDate" | "setValueAs">
  ): UseControllerProps<TFieldValues, TFieldName> => {
    return {
      name: name,
      control: form.control as any,
      rules: {
        required: true,
        ...rules,
      },
    };
  };

  return {
    ...form,
    onSubmit,
    globalError,
    setGlobalError,
    cleanGlobalError,
    register,
    regField: reg,
  } as any;
};
