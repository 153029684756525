import React, { CSSProperties } from "react";
import cx from "classnames";
import styles from "./auth-layout.module.scss";
import { AuthLayoutMain } from "modules/auth/ui/auth-layout/auth-layout-main";
import { AuthLayoutBottom } from "modules/auth/ui/auth-layout/auth-layout-bottom";

export interface IAuthLayout {
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  htmlId?: string;
}

export const AuthLayout = (props: IAuthLayout) => {
  return (
    <div className={cx(styles.auth_layout, props.className)} style={props.style} id={props.htmlId}>
      <div className={styles.header}>
        <div className={styles.logo}>Control Online</div>
      </div>
      {props.children}
    </div>
  );
};

AuthLayout.defaultProps = {};

AuthLayout.Main = AuthLayoutMain;
AuthLayout.Bottom = AuthLayoutBottom;
