import React, { CSSProperties } from "react";
import cx from "classnames";
import styles from "./auth-form.module.scss";
import { Button, Input } from "shared/ui";
import { useAppForm } from "shared/lib/forms";
import { api } from "shared/api/init";
import { UserAuthRequestDto, UserAuthStateDto } from "shared/api/back";

export interface IAuthForm {
  onSuccess: (response: UserAuthStateDto, values: UserAuthRequestDto) => void;
  className?: string;
  style?: CSSProperties;
  htmlId?: string;
}

export const AuthForm = (props: IAuthForm) => {
  const form = useAppForm({ onSubmit: api.userAuth.userAuthAuth, onSuccess: props.onSuccess });

  return (
    <form
      onSubmit={form.onSubmit}
      className={cx(styles.auth_form, props.className)}
      style={props.style}
      id={props.htmlId}
    >
      <Input
        size="large"
        type="email"
        placeholder="Электронная почта"
        autoFocus
        required
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
        {...form.register("email")}
      />

      <Button size="large" htmlType="submit" loading={form.formState.isSubmitting}>
        Продолжить
      </Button>
    </form>
  );
};

AuthForm.defaultProps = {};
