import React, { CSSProperties } from "react";
import cx from "classnames";
import styles from "./auth-layout-bottom.module.scss";

export interface IAuthLayoutBottom {
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  htmlId?: string;
}

export const AuthLayoutBottom = (props: IAuthLayoutBottom) => {
  return (
    <div className={cx(styles.auth_layout_bottom, props.className)} style={props.style} id={props.htmlId}>
      {props.children}
    </div>
  );
};

AuthLayoutBottom.defaultProps = {};
